@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.spinner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 70px;
  height: 70px;
}

.spinner > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  border: 6px solid #027AD0;
  border-radius: 50%;
  border-top-color: transparent;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
